import React, { useState, useEffect } from "react"
import { Row, Col, Button } from "reactstrap"
// import { Form, Input, FormGroup, Label } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { PropagateLoader } from "react-spinners"
import { toast } from "react-toastify"
import Captcha from "../../../components/common/captcha"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"
import { isValidEmail } from "../../../utils/common"
import { fetch_post } from "../../../utils/fetch"
import * as Sentry from "@sentry/gatsby"

export default function TrademarkPolicyReport(props) {
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [markercode, setMarkercode] = useState("")
  const [inputCode, setInputCode] = useState("")
  const [form, setForm] = useState({})
  const [formCopy, setFormCopy] = useState({})

  const get_description_format = (name, isdrp = false, drpValueList = null) => {
    var question = document.getElementById(`${name}_label`).innerText
    var val = form[name]
    if (isdrp) {
      let searchRes = drpValueList.filter(function (item) {
        return val === item.id
      })
      if (searchRes.length > 0) val = searchRes[0].val
    }
    return `Q). ${question}\nA). ${val}\n--------------------------------\n`
  }

  const generate_description_text = () => {
    var ret = ""
    ret += get_description_format("name")
    ret += get_description_format("emailaddress")
    ret += get_description_format("companyName")
    ret += get_description_format("companyWebsite")
    ret += get_description_format("trademarkWordOrSymbol")
    ret += get_description_format("trademarkRegistrationNumber")
    ret += get_description_format("trademarkRegistrationOffice")
    ret += get_description_format("descriptionOfConfussion")
    ret += get_description_format("requestedActions")
    ret += get_description_format("isContentAuthorised")
    ret += get_description_format("isInformationAccurate")
    ret += get_description_format("fullName")
    return ret
  }

  const clearForm = () => {
    return {
      name: "",
      emailaddress: "",
      companyName: "",
      companyWebsite: "",
      trademarkWordOrSymbol: "",
      trademarkRegistrationNumber: "",
      trademarkRegistrationOffice: "",
      descriptionOfConfussion: "",
      requestedActions: "",
      isContentAuthorised: false,
      isInformationAccurate: false,
      fullName: "",
    }
  }

  const validate_form = () => {
    if (_.isEmpty(form.name)) return "Name is required"
    else if (_.isEmpty(form.emailaddress)) return "Email is required"
    else if (!isValidEmail(form.emailaddress)) return "Email address should be valid"
    else if (_.isEmpty(form.companyName)) return "Company Name is requierd"
    else if (_.isEmpty(form.companyWebsite)) return "Company website is required"
    else if (_.isEmpty(form.trademarkWordOrSymbol)) return "Trademark word or symbol is required"
    else if (_.isEmpty(form.trademarkRegistrationNumber))
      return "Trademark Registration Number is required"
    else if (_.isEmpty(form.trademarkRegistrationOffice))
      return "Trademark Office details are required"
    else if (_.isEmpty(form.descriptionOfConfussion)) return "All fields are required"
    else if (_.isEmpty(form.requestedActions)) return "All fields are required"
    else if (!form.isContentAuthorised) return "All fields are required"
    else if (!form.isInformationAccurate) return "All fields are required"
    else if (_.isEmpty(form.fullName)) return "You full legal name is required"
    else if (_.isEmpty(inputCode)) return "Captcha code is required"
    else if (inputCode !== markercode) return "Incorrect captch code entered"
    return null
  }

  const hande_change = e => {
    let temp = JSON.parse(JSON.stringify(form))
    temp[e.target.name] = e.target.type === "checkbox" ? e.target.checked : e.target.value
    setForm(temp)
    // console.log(temp)
  }

  const has_changes = () => {
    return JSON.stringify(form) !== JSON.stringify(formCopy)
  }

  const cancel_changes = () => {
    let temp = JSON.parse(JSON.stringify(clearForm()))
    setForm(temp)
    setFormCopy(temp)
  }

  const getMarkerCode = code => {
    console.log("marker code is", code)
    setMarkercode(code)
  }

  const reset_form = () => {
    var obj = clearForm()
    setForm(obj)
    setFormCopy(obj)
    setInputCode("")
  }

  const submit_form = async () => {
    var res = validate_form()
    if (res !== null) {
      toast(`${res}`, {
        type: "error",
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    } else {
      var token = await getAccessTokenSilently()
      var description = generate_description_text()
      console.log(description)
      // return
      fetch_post(`ui/support/trademark/violation/create`, { theFormData: description }, token)
        .then(response => {
          console.log(response)
          if (response.status.code === 201) {
            toast(`${response.status.description}`, {
              type: "success",
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
            reset_form()
          }
        })
        .catch(error => {
          Sentry.captureException(error)
          console.log(`Error while creating takedown ticket from: ${error}`)
        })
    }
  }

  useEffect(() => {
    reset_form()
  }, [])

  return (
    <>
      {loading && (
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={{ size: 12 }}
          sm={{ size: 4, offset: 4 }}
        >
          <PropagateLoader className="align-self-center" size={20} color={"#9c3"} loading={true} />
        </Col>
      )}
      <div>
        <p>
          In order to investigate trademark policy violations, please provide all of the following
          information:
        </p>
        <div className="mb-3">
          <label id="name_label" className="form-label">
            Name
          </label>
          <input
            type="text"
            placeholder="Your name"
            className="form-control"
            id="name"
            name="name"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.name || ""}
          />
          {/* <div id="emailHelp" className="form-text">
              We'll never share your email with anyone else.
            </div> */}
        </div>
        <div className="mb-3">
          <label id="emailaddress_label" className="form-label">
            Email Address
          </label>
          <input
            type="text"
            placeholder="Your email address"
            className="form-control"
            id="emailaddress"
            name="emailaddress"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.emailaddress || ""}
          />
        </div>
        <div className="mb-3">
          <label id="companyName_label" className="form-label">
            Company Name
          </label>
          <input
            type="text"
            placeholder="Your Company Namey"
            className="form-control"
            id="companyName"
            name="companyName"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.companyName || ""}
          />
        </div>
        <div className="mb-3">
          <label id="companyWebsite_label" className="form-label">
            Company Website
          </label>
          <input
            type="text"
            placeholder="Your Company Website"
            className="form-control"
            id="companyWebsite"
            name="companyWebsite"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.companyWebsite || ""}
          />
        </div>
        <div className="mb-3">
          <label id="trademarkWordOrSymbol_label" className="form-label">
            Trademarked word, symbol, etc
          </label>
          <input
            type="text"
            placeholder="Your trademarked word, symbol, etc"
            className="form-control"
            id="trademarkWordOrSymbol"
            name="trademarkWordOrSymbol"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.trademarkWordOrSymbol || ""}
          />
        </div>
        <div className="mb-3">
          <label id="trademarkRegistrationNumber_label" className="form-label">
            Trademark registration number
          </label>
          <input
            type="text"
            placeholder=""
            className="form-control"
            id="trademarkRegistrationNumber"
            name="trademarkRegistrationNumber"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.trademarkRegistrationNumber || ""}
          />
        </div>
        <div className="mb-3">
          <label id="trademarkRegistrationOffice_label" className="form-label">
            Trademark registration office (e.g., USPTO)
          </label>
          <input
            type="text"
            placeholder=""
            className="form-control"
            id="trademarkRegistrationOffice"
            name="trademarkRegistrationOffice"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.trademarkRegistrationOffice || ""}
          />
        </div>
        <div className="mb-3">
          <label id="descriptionOfConfussion_label" className="form-label">
            Description of confusion (e.g., passing off as your company, including specific
            descriptions of content or behavior)
          </label>
          <textarea
            className="form-control"
            rows="5"
            style={{ resize: "none" }}
            id="descriptionOfConfussion"
            name="descriptionOfConfussion"
            onChange={e => hande_change(e)}
            value={form.descriptionOfConfussion || ""}
          ></textarea>
        </div>
        <div className="mb-3">
          <label id="requestedActions_label" className="form-label">
            Requested Action (e.g., removal of violating account or transfer of trademarked username
            to an existing company account)
          </label>
          <textarea
            className="form-control"
            rows="5"
            style={{ resize: "none" }}
            id="requestedActions"
            name="requestedActions"
            onChange={e => hande_change(e)}
            value={form.requestedActions || ""}
          ></textarea>
        </div>
        <div className="mb-3">
          <input
            type="checkbox"
            placeholder="Your name"
            id="isContentAuthorised"
            name="isContentAuthorised"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.isContentAuthorised || false}
          />{" "}
          <span id="isContentAuthorised_label" className="form-label">
            I have a good faith belief that use of the trademark described above is not authorized
            by the trademark owner, or its agent, or the law. I have taken nominative and other fair
            uses into consideration.
          </span>
        </div>
        <div className="mb-3">
          <input
            type="checkbox"
            placeholder="Your name"
            id="isInformationAccurate"
            name="isInformationAccurate"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.isInformationAccurate || false}
          />{" "}
          <span id="isInformationAccurate_label" className="form-label">
            I swear, under penalty of perjury, that the information in this notification is accurate
            and that I am the trademark owner, or am authorized to act on behalf of the owner, of an
            exclusive right that is allegedly infringed.
          </span>
        </div>
        <div className="mb-3">
          <label id="fullName_label" className="form-label">
            Please type your full legal name below to sign this request.
          </label>
          <input
            type="text"
            placeholder="Your full legal name"
            className="form-control"
            id="fullName"
            name="fullName"
            onChange={e => hande_change(e)}
            aria-describedby="emailHelp"
            value={form.fullName || ""}
          />
        </div>
        <form className="row g-3">
          <div className="col-md-2">
            <label for="inputCity" className="form-label">
              Captcha:
            </label>
            <input
              type="text"
              name="inputCode"
              id="inputCode"
              value={inputCode}
              onChange={event => setInputCode(event.target.value)}
            />
          </div>

          <div className="col-md-2">
            <label for="inputZip" className="form-label">
              &nbsp;
            </label>
            <Captcha onChange={getMarkerCode}></Captcha>
          </div>
        </form>
        <div className="mb-3">See CodeLinaro Policies for more details.</div>
        <button
          type="button"
          onClick={() => submit_form()}
          disabled={!has_changes()}
          className="btn btn-primary"
        >
          Submit
        </button>
        &nbsp;
        <button
          type="button"
          onClick={() => reset_form()}
          disabled={!has_changes()}
          className="btn btn-info"
        >
          Reset
        </button>
      </div>
    </>
  )
}
