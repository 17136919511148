import React from "react"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/header/header"
import { useUserOrgs } from "../../../hooks/use-user"
import TrademarkPolicyReport from "../../../components/trademark/policy/report"
import "../../../styles/metrics.scss"
import { Loading } from "../../../components/loading"
import Breadcrumbs from "../../../components/breadcrumbs/breadcrumbs"

const ReportPage = () => {
  const {
    loading,
    currentOrg: { index },
  } = useUserOrgs()

  if (loading) {
    return <Loading />
  }
  return (
    <Layout pageTitle="Report Trademark Policy Violations">
      <Breadcrumbs breadcrumbs={[{ label: "DMCA" }]} />
      <Header contentPage={true} className="contentPage">
        <h1 className="display-4 font-weight-bold">Reporting Trademark Policy Violations?</h1>
      </Header>
      <Content>
        <TrademarkPolicyReport orgIndex={index} />
      </Content>
    </Layout>
  )
}

export default ReportPage
